import { roundFloat } from "client/utils/roundFloat";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setHeight = (state: BulkResizeState, { payload: _height }: IPayload) => {
    const height = _height > 0 ? _height : 0;

    state.resizeSettings.byDimension.height = height;

    state.resizeSettings.byDimension.lastModified = "height";
    state.lastModified = "height";

    if (state.resizeSettings.isLocked) {
        state.resizeSettings.byDimension.width = 0;
    }

    state.images = state.images.map((image) => {
        const newHeight = height > 0 ? height : image.original.height;
        const newWidth = roundFloat((newHeight / image.original.height) * image.original.width);

        return {
            ...image,
            preview: {
                ...image.preview,
                height: newHeight,
                width: state.resizeSettings.isLocked
                    ? state.resizeSettings.unit === "pixels"
                        ? Math.round(newWidth)
                        : newWidth
                    : image.preview.width,
            },
        };
    });
};
