import { Unit } from "store/types/unit";

export const INCH_TO_MM = 25.4;

export const convertFromPixels = (value: number, unit: Unit, _dpi?: number): number => {
    const dpi = typeof _dpi === "undefined" ? 1 : _dpi > 0 ? _dpi : 1;

    if (unit === "cm") {
        // return pxToCm(value);
        return (value * (INCH_TO_MM / 10)) / dpi;
    }

    if (unit === "mm") {
        // return pxToCm(value) * 10;
        return (value * INCH_TO_MM) / dpi;
    }

    if (unit === "inch") {
        // return pxToCm(value) / 2.54;
        return value / dpi;
    }

    return value;
};

const pxToCm = (value: number) => {
    return value / 37.7952755906;
};
