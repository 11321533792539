import { toggleHorizontal as flipToggleHorizontal } from "./flip/toggleHorizontal";
import { toggleVertical as flipToggleVertical } from "./flip/toggleVertical";
import { setRotation as rotateSetRotation } from "./rotate/setRotation";
import { setStraighten as rotateSetStraighten } from "./rotate/setStraighten";
import { setImage } from "./image/setImage";
import { reset as flipReset } from "./flip/reset";
import { reset as rotateReset } from "./rotate/reset";
import { setCurrentType } from "./setCurrentType";
import { resetSettings } from "./resetSettings";
import { setIsLoading } from "./setIsLoading";
import { setUploadData } from "./setUploadData";

export const reducers = {
    // image
    setImage,
    // flip
    flipToggleHorizontal,
    flipToggleVertical,
    flipReset,
    //rotate
    rotateSetRotation,
    rotateSetStraighten,
    rotateReset,
    setCurrentType,
    resetSettings,
    setIsLoading,
    setUploadData,
};
