import { MAX_RESIZE_PERCENTAGE } from "store/features/resizeEditor/reducers/resize/setPercentage";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setPercentage = (
    state: BulkResizeState,
    { payload: percentage }: IPayload
) => {
    if (!percentage || percentage < 0 || percentage > MAX_RESIZE_PERCENTAGE) return;

    state.resizeSettings.byPercentage.percentage = percentage;
    state.lastModified = "percentage";

    state.images = state.images.map((image) => {
        const width = Math.floor((image.original.width * percentage) / 100);
        const height = Math.floor((image.original.height * percentage) / 100);

        return {
            ...image,
            preview: {
                ...image.preview,
                width,
                height,
            },
        };
    });
};
