import { roundFloat } from "client/utils/roundFloat";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const setHeight = (state: ResizeEditorState, { payload: _height }: IPayload) => {
    let height = _height;
    if (!height || height < 0) {
        height = 0;
    }
    if (state.currentType === "crop") {
        state.resize.byDimension.height = height;
        return;
    }

    let heightResult = height;
    let widthResult = state.resize.byDimension.width;

    if (state.resize.isLocked && state.image) {
        widthResult = state.image.ratio * heightResult;
    }

    state.resize.byDimension.width = state.resize.unit === "pixels" ? Math.ceil(widthResult) : roundFloat(widthResult);
    state.resize.byDimension.height =
        state.resize.unit === "pixels" ? Math.round(heightResult) : roundFloat(heightResult);
    state.resize.byDimension.lastModified = "height";
};
