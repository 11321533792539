import { createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IPostReturn, post } from "client/services/Backend/post";
import type { Job, Task } from "@freeconvert/freeconvert-node/dist/types";
import { CompressImage, CompressState } from "../types";

export const createCompressJob = createAsyncThunk(
    "compress/createCompressJob",
    async ({
        image,
        importTask,
        settings,
    }: {
        image: CompressImage;
        importTask: Task;
        settings: CompressState["compressionSettings"];
    }): Promise<IPostReturn<Job>> => {
        const options: any = {};
        if (settings.type === "max_file_size") {
            options.max_file_size = settings.maxFileSize.value;
        } else {
            options.quality = settings.quality.value;
        }

        const body = {
            input: importTask.id,
            input_format: image.format.toLowerCase(),
            output_format: image.format.toLowerCase(),
            options,
        };
        const result = await post<Job>("/api/jobs/compress", body);

        // this is so that redux toolkit handles this as rejected
        if (result.error) {
            throw {
                error: result.error,
            };
        }

        return result;
    },
);

export const handleCreateCompressJob = (builder: ActionReducerMapBuilder<CompressState>) => {
    builder.addCase(
        createCompressJob.fulfilled,
        (
            state,
            {
                payload,
                meta: {
                    arg: {
                        image: { id },
                    },
                },
            },
        ) => {
            const image = state.images.find((image) => image.id === id);
            if (!image || !payload.result || payload.error || !image.compressing) return;
            image.compressing = {
                ...image.compressing,
                job: payload.result,
            };
        },
    );

    builder.addCase(
        createCompressJob.pending,
        (
            state,
            {
                meta: {
                    arg: { image },
                },
            },
        ) => {
            state.images = state.images.map((_image) => {
                if (_image.id !== image.id) return _image;

                return {
                    ..._image,
                    compressing: {
                        progress: "continuous",
                    },
                    state: "compressing",
                };
            });
        },
    );
};
